import { Navigate, useSearchParams } from "react-router-dom";
import "../../css/Public/Login.css";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../App";
import logo_mesbar from "../../images/new_logo_menu.png";
import {
  registerUser,
  restPasswordUser,
} from "../../backend/Admin/UserManager/UserManager";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPage from "./ErrorPage";
import email_img from "../../images/email_img.png";
import clock_img from "../../images/clock_img.png";
import tick_img from "../../images/tick_img_step.png";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { VscEyeClosed } from "react-icons/vsc";
import { VscEye } from "react-icons/vsc";

function Login({
  errorMessages,
  setErrorMessages,
  isSubmitted,
  handleSubmit,
  isSuperUser,
  isNormalUser,
  isJuniorAnalyzer,
  isSeniorAnalyzer,
  isEmailChecked,
  isIdentityChecked,
  isTaskChecked,
  isPayChecked,
  isPreperedChecked,
  isDemoChecked,
  isProjectReadyChecked,
  isRequestDemoChecked,
  saveLoginStatus,
  setIsSubmitted,
}) {
  const { language, toggleLanguage } = useContext(LanguageContext);

  const [islanguageMenuOpen, setIslanguageMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIslanguageMenuOpen(!islanguageMenuOpen);
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    // Access the "package" query parameter
    const packageValue = searchParams.get('package');
    if (packageValue) {
      // Save the value to local storage
      localStorage.setItem('package', packageValue);
      console.log(`Saved package to local storage: ${packageValue}`);
    }
  }, [searchParams]);

  const handleLanguageToggle = (selectedLanguage) => {
    if (selectedLanguage !== language) {
      toggleLanguage(selectedLanguage);
    }
    setIslanguageMenuOpen(false);
  };

  const [activeTab, setActiveTab] = useState("login");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRegister, setShowPasswordRegister] = useState(false);
  const [showPasswordRegisterConf, setShowPasswordRegisterConf] =
    useState(false);

  const [formData, setFormData] = useState({
    userNameRegister: "",
    email: "",
    passregister: "",
    confirm_pass: "",
  });

  function validateEmail(email) {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      if (!validateEmail(value)) {
        if (language === "ar") {
          setErrorMessages("يرجى إرسال بريد إلكتروني موثوق به");
        } else {
          setErrorMessages("Please enter a valid email !");
        }
      } else {
        setErrorMessages("");
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleConfirmAdd = async () => {
    try {
      if (formData.passregister !== formData.confirm_pass) {
        if (language === "en") {
          Swal.fire({
            icon: "Error",
            title: "Oops...",
            text: "Passwords do not Match",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "Error",
            title: "أوه",
            text: "كلمة المرور غير مطابقة",
            confirmButtonText: "حسناً",
          });
        }
        return;
      }

      if (!validateEmail(formData.email)) {
        if (language === "en") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please Enter a Valid Email!",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "Error",
            title: "أوه",
            text: "يرجى إدخال عنوان بريد الكتروني صحيح",
            confirmButtonText: "حسناً",
          });
        }
        return;
      }

      console.log(
        formData.userNameRegister,
        formData.email,
        formData.passregister
      );

      const result = await registerUser(
        formData.userNameRegister,
        formData.email,
        formData.passregister
      );

      if (!result.hasError) {
        setFormData({
          userNameRegister: "",
          email: "",
          passregister: "",
        });
        if (language === "en") {
          Swal.fire({
            title: "Success!",
            text: "Registration was successful. Please confirm your email to enter the site.",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "نَجاح",
            text: "تمت عملية التسجيل بنجاح. الرجاء تأكيد بريدك الإلكتروني للدخول إلى الموقع",
            icon: "success",
          });
        }
        window.location.assign("/");
      } else {
        const errorData = JSON.parse(result.errorText);

        if (errorData.username) {
          const errorMessage = errorData.username[language];

          if (language === "en") {
            Swal.fire({
              title: "Oops...",
              text: `Username Error: ${errorMessage}`,
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "...أوه",
              text: `خطأ في اسم المستخدم : ${errorMessage}`,
              icon: "error",
              confirmButtonText: "حسناً",
            });
          }
        }

        if (errorData.email) {
          const errorMessage = errorData.email[language];

          if (language === "en") {
            Swal.fire({
              title: "Oops...",
              text: `Email Error: ${errorMessage}`,
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "...أوه",
              text: `خطأ في البريد الإلكتروني : ${errorMessage}`,
              icon: "error",
              confirmButtonText: "حسناً",
            });
          }
        }

        console.error("Request failed:", result);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  // reset password

  const [formDataRestPass, setFormDataRestPass] = useState({
    userNameRestPass: "",
    emailRestPass: "",
  });

  const [emailErrorRestPass, setEmailErrorRestPass] = useState("");

  const handleInputChangeRestPass = (e) => {
    const { name, value } = e.target;
    if (name === "emailRestPass") {
      if (!validateEmail(value)) {
        if (language === "ar") {
          setEmailErrorRestPass("يرجى إرسال بريد إلكتروني موثوق به");
        } else {
          setEmailErrorRestPass("Please enter a valid email !");
        }
      } else {
        setEmailErrorRestPass("");
      }
    }
    setFormDataRestPass({
      ...formDataRestPass,
      [name]: value,
    });
  };

  const handleConfirmAddRestPass = async () => {
    try {
      if (!validateEmail(formDataRestPass.emailRestPass)) {
        if (language === "en") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Please Enter a Valid Email!",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "Error",
            title: "أوه",
            text: "يرجى إدخال عنوان بريد الكتروني صحيح",
            confirmButtonText: "حسناً",
          });
        }
        return;
      }

      console.log(
        formDataRestPass.userNameRestPass,
        formDataRestPass.emailRestPass
      );

      const result = await restPasswordUser(
        formDataRestPass.userNameRestPass,
        formDataRestPass.emailRestPass
      );

      if (!result.hasError) {
        setFormDataRestPass({
          userNameRestPass: "",
          emailRestPass: "",
        });
        if (language === "en") {
          Swal.fire({
            title: "Success!",
            text: "Registration was successful. Please confirm your email to enter the site.",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "نَجاح",
            text: "تمت عملية التسجيل بنجاح. الرجاء تأكيد بريدك الإلكتروني للدخول إلى الموقع",
            icon: "success",
          });
        }
      } else {
        const errorData = JSON.parse(result.errorText);

        if (errorData.userNameRestPass) {
          const errorMessage = errorData.userNameRestPass[language];

          Swal.fire({
            icon: "error",
            title: language === "en" ? "Oops..." : "أوه",
            confirmButtonText: language === "ar" ? "حسناً" : "OK",
            text:
              language === "en"
                ? `Username Error: ${errorMessage}`
                : `خطأ في اسم المستخدم : ${errorMessage}`,
          });
        }

        if (errorData.emailRestPass) {
          const errorMessage = errorData.emailRestPass[language];

          Swal.fire({
            icon: "error",
            title: language === "en" ? "Oops..." : "أوه",
            confirmButtonText: language === "ar" ? "حسناً" : "OK",
            text:
              language === "en"
                ? `Email Error: ${errorMessage}`
                : `خطأ في البريد الإلكتروني : ${errorMessage}`,
          });
        }

        console.error("Request failed:", result);
      }
    } catch (error) {
      if (language === "en") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("لقد حدث خطأ ما");
      }
    }
  };

  const renderForm = (
    <div className="login_page">
      <div className="login_page_header">
        <div className="language-toggle-menu">
          <div className="language-toggle-container">
            <div className="language-toggle-button" onClick={toggleMenu}>
              {language === "ar" ? "العربية" : "English (US)"}
              {islanguageMenuOpen ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
            </div>
            {islanguageMenuOpen && (
              <div
                className={`language-menu ${islanguageMenuOpen ? "show" : ""}`}
                id="language_menu"
              >
                <div
                  className={language === "ar" ? "active" : ""}
                  onClick={() => handleLanguageToggle("ar")}
                >
                  العربية
                </div>
                <div
                  className={language === "en" ? "active" : ""}
                  onClick={() => handleLanguageToggle("en")}
                >
                  English (US)
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <img src={logo_mesbar} alt="" />
        </div>
      </div>
      <div className="login_page_style">
        {activeTab === "register" && (
          <div className="login_page_body create_user">
            {language === "en" ? (
              <div className="login_page_body_row">
                {/* <div className="items_login_page_body">
                  <img src={create_user_img} alt="" />
                </div> */}
                <div className="items_login_page_body header_create">
                  <h1>Create Account</h1>
                </div>
                <form
                  id="login_form_newlogin"
                  onSubmit={handleConfirmAdd}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleConfirmAdd(e);
                    }
                  }}
                >
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type="text"
                        name="userNameRegister"
                        value={formData.userNameRegister}
                        onChange={handleInputChange}
                        required
                      />
                      <label
                        htmlFor="userNameRegister"
                        className="label_items_login_page_body"
                      >
                        user name
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type="email"
                        required
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <label
                        htmlFor="email"
                        className="label_items_login_page_body"
                      >
                        email address
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type={showPasswordRegister ? "text" : "password"}
                        required
                        name="passregister"
                        id="passwordInput"
                        value={formData.password}
                        onChange={handleInputChange}
                        autoComplete="new-password"
                      />
                      <button
                        type="button"
                        className={`password_toggle_button_newlogin_en ${
                          showPasswordRegister ? "active" : ""
                        }`}
                        onClick={() =>
                          setShowPasswordRegister(!showPasswordRegister)
                        }
                      >
                        {showPasswordRegister ? (
                          <VscEyeClosed id="password_toggle_button_newlogin_en_ico" />
                        ) : (
                          <VscEye id="password_toggle_button_newlogin_en_ico" />
                        )}
                      </button>
                      <label
                        htmlFor="passregister"
                        className="label_items_login_page_body"
                      >
                        password
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type={showPasswordRegisterConf ? "text" : "password"}
                        required
                        name="confirm_pass"
                        id="passwordInput"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                      <button
                        type="button"
                        className={`password_toggle_button_newlogin_en ${
                          showPasswordRegisterConf ? "active" : ""
                        }`}
                        onClick={() =>
                          setShowPasswordRegisterConf(!showPasswordRegisterConf)
                        }
                      >
                        {showPasswordRegisterConf ? (
                          <VscEyeClosed id="password_toggle_button_newlogin_en_ico" />
                        ) : (
                          <VscEye id="password_toggle_button_newlogin_en_ico" />
                        )}
                      </button>
                      <label
                        htmlFor="confirm_pass"
                        className="label_items_login_page_body"
                      >
                        re-enter ypur password
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body resetpass">
                    <button
                      onClick={handleConfirmAdd}
                      className="items_login_page_body_button"
                    >
                      sumit
                    </button>
                  </div>
                </form>
                <div className="items_login_page_body footer">
                  <div className="items_login_page_body_footer_item">
                    <span className="items_login_page_body_footer_span_last">
                      Already have account?
                    </span>
                    <button
                      onClick={() => handleTabChange("login")}
                      className="items_login_page_body_footer_btn"
                    >
                      log in
                    </button>
                  </div>
                </div>
                {emailErrorRestPass && (
                  <div className="error_message_login">
                    {emailErrorRestPass}
                  </div>
                )}
              </div>
            ) : (
              <div className="login_page_body_row">
                {/* <div className="items_login_page_body">
                  <img src={create_user_img} alt="" />
                </div> */}
                <div className="items_login_page_body header">
                  <h1>إنشاء حساب</h1>
                </div>

                <div className="items_login_page_body">
                  <div className="input_container_items_login_page_body ar">
                    <input
                      type="text"
                      name="userNameRegister"
                      value={formData.userNameRegister}
                      onChange={handleInputChange}
                      required
                    />
                    <label
                      htmlFor="userNameRegister"
                      className="label_items_login_page_body_ar"
                    >
                      اسم المستخدم
                    </label>
                    <div className="underline_input_container_items_login_page_body"></div>
                  </div>
                </div>
                <div className="items_login_page_body">
                  <div className="input_container_items_login_page_body ar">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                    <label
                      htmlFor="email"
                      className="label_items_login_page_body_ar"
                    >
                      البريد الإلكتروني
                    </label>
                    <div className="underline_input_container_items_login_page_body"></div>
                  </div>
                </div>
                <div className="items_login_page_body">
                  <div className="input_container_items_login_page_body ar">
                    <input
                      type={showPasswordRegister ? "text" : "password"}
                      name="passregister"
                      id="passwordInput"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                      autoComplete="new-password"
                    />
                    <button
                      type="button"
                      className={`password_toggle_button_newlogin ${
                        showPasswordRegister ? "active" : ""
                      }`}
                      onClick={() =>
                        setShowPasswordRegister(!showPasswordRegister)
                      }
                    >
                      {showPasswordRegister ? (
                        <VscEyeClosed id="password_toggle_button_newlogin_en_ico" />
                      ) : (
                        <VscEye id="password_toggle_button_newlogin_en_ico" />
                      )}
                    </button>
                    <label
                      htmlFor="passregister"
                      className="label_items_login_page_body_ar"
                    >
                      كلمة المرور
                    </label>
                    <div className="underline_input_container_items_login_page_body"></div>
                  </div>
                </div>
                <div className="items_login_page_body">
                  <div className="input_container_items_login_page_body ar">
                    <input
                      type={showPasswordRegisterConf ? "text" : "password"}
                      required
                      name="confirm_pass"
                      id="passwordInput"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    <button
                      type="button"
                      className={`password_toggle_button_newlogin ${
                        showPasswordRegisterConf ? "active" : ""
                      }`}
                      onClick={() =>
                        setShowPasswordRegisterConf(!showPasswordRegisterConf)
                      }
                    >
                      {showPasswordRegisterConf ? (
                        <VscEyeClosed id="password_toggle_button_newlogin_en_ico" />
                      ) : (
                        <VscEye id="password_toggle_button_newlogin_en_ico" />
                      )}
                    </button>
                    <label
                      htmlFor="confirm_pass"
                      className="label_items_login_page_body_ar"
                    >
                      تأكيد كلمة المرور
                    </label>
                    <div className="underline_input_container_items_login_page_body"></div>
                  </div>
                </div>
                <div className="items_login_page_body resetpass">
                  <button
                    onClick={handleConfirmAdd}
                    className="items_login_page_body_button"
                  >
                    إنشاء حساب
                  </button>
                </div>
                <div className="items_login_page_body footer">
                  <div className="items_login_page_body_footer_item">
                    <button
                      onClick={() => handleTabChange("login")}
                      className="items_login_page_body_footer_btn"
                    >
                      تسجيل الدخول
                    </button>
                    <span className="items_login_page_body_footer_span_last">
                      هل لديك حساب؟
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === "login" && (
          <div className="login_page_body">
            {language === "en" ? (
              <div className="login_page_body_row">
                {/* <div className="items_login_page_body">
                  <img src={user_img} alt="" />
                </div> */}
                <div className="items_login_page_body header">
                  <h1>Log into your account</h1>
                  <span className="items_login_page_body_footer_span">
                    welcome back
                  </span>
                  {Object.keys(errorMessages).length > 0 && (
                    <div className="error_message_login">
                      {renderErrorMessage("email_pass")}
                    </div>
                  )}
                </div>
                <form
                  id="login_form_newlogin"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  onSubmit={handleSubmit}
                >
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input type="text" required name="username" />
                      <label
                        htmlFor="input"
                        className="label_items_login_page_body"
                      >
                        user name
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="pass"
                        id="passwordInput"
                        required
                        autoComplete="new-password"
                      />
                      <button
                        type="button"
                        className={`password_toggle_button_newlogin_en ${
                          showPassword ? "active" : ""
                        }`}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VscEyeClosed id="password_toggle_button_newlogin_en_ico" />
                        ) : (
                          <VscEye id="password_toggle_button_newlogin_en_ico" />
                        )}
                      </button>
                      <label
                        htmlFor="passwordInput"
                        className="label_items_login_page_body"
                      >
                        password
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body_remember en">
                    <input type="checkbox" />
                    <label className="label_items_login_page_body">
                      remember me
                    </label>
                  </div>

                  <div className="items_login_page_body">
                    <button
                      type="submit"
                      className="items_login_page_body_button"
                    >
                      log in
                    </button>
                  </div>
                </form>
                <div className="items_login_page_body footer">
                  <button
                    onClick={() => handleTabChange("resetpass")}
                    className="items_login_page_body_footer_span btn"
                  >
                    forgot password?
                  </button>
                  <div className="items_login_page_body_footer_item">
                    <span className="items_login_page_body_footer_span_last">
                      don’t have account?
                    </span>
                    <button
                      className="items_login_page_body_footer_btn"
                      onClick={() => handleTabChange("register")}
                    >
                      create
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login_page_body_row">
                {/* <div className="items_login_page_body">
                  <img src={user_img} alt="" />
                </div> */}
                <div className="items_login_page_body header">
                  <h1>تسجيل الدخول</h1>
                  <span className="items_login_page_body_footer_span">
                    أهلا بك من جديد
                  </span>
                  {Object.keys(errorMessages).length > 0 && (
                    <div className="error_message_login">
                      {renderErrorMessage("email_pass")}
                    </div>
                  )}
                </div>

                <form
                  id="login_form_newlogin"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  onSubmit={handleSubmit}
                >
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body ar">
                      <input
                        type="text"
                        required
                        name="username"
                        className="input_login"
                      />
                      <label
                        htmlFor="username"
                        className="label_items_login_page_body_ar"
                      >
                        اسم المستخدم
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body ar">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="pass"
                        id="passwordInput"
                        required
                        autoComplete="new-password"
                      />
                      <button
                        type="button"
                        className={`password_toggle_button_newlogin ${
                          showPassword ? "active" : ""
                        }`}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VscEyeClosed id="password_toggle_button_newlogin_en_ico" />
                        ) : (
                          <VscEye id="password_toggle_button_newlogin_en_ico" />
                        )}
                      </button>
                      <label
                        htmlFor="passwordInput"
                        className="label_items_login_page_body_ar"
                      >
                        كلمة المرور
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>

                  <div className="items_login_page_body_remember">
                    <label className="label_items_login_page_body_ar">
                      ذکّرني
                    </label>
                    <input type="checkbox" />
                  </div>

                  <div className="items_login_page_body">
                    <button
                      type="submit"
                      className="items_login_page_body_button ar"
                    >
                      تسجيل الدخول
                    </button>
                  </div>
                </form>
                <div className="items_login_page_body footer ar">
                  <button
                    onClick={() => handleTabChange("resetpass")}
                    className="items_login_page_body_footer_span btn ar"
                  >
                    نسيت كلمة المرور؟
                  </button>

                  <div className="items_login_page_body_footer_item">
                    <button
                      className="items_login_page_body_footer_btn ar"
                      onClick={() => handleTabChange("register")}
                    >
                      إنشاء حساب
                    </button>
                    <span className="items_login_page_body_footer_span_last">
                      ليس لديك حساب؟
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === "resetpass" && (
          <div className="login_page_body">
            {language === "en" ? (
              <div className="login_page_body_row">
                {/* <div className="items_login_page_body">
                  <img src={user_img} alt="" />
                </div> */}
                <div className="items_login_page_body header">
                  <h1>Reset Password</h1>
                </div>
                <form
                  id="login_form_newlogin"
                  onSubmit={handleConfirmAddRestPass}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleConfirmAddRestPass(e);
                    }
                  }}
                >
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type="text"
                        required
                        name="userNameRestPass"
                        value={formData.userNameRestPass}
                        onChange={handleInputChangeRestPass}
                      />
                      <label
                        htmlFor="input"
                        className="label_items_login_page_body"
                      >
                        user name
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body">
                      <input
                        type="email"
                        required
                        name="emailRestPass"
                        value={formData.emailRestPass}
                        onChange={handleInputChangeRestPass}
                      />
                      <label
                        htmlFor="input"
                        className="label_items_login_page_body"
                      >
                        email address
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>

                  <div className="items_login_page_body resetpass">
                    <button
                      type="submit"
                      onClick={handleConfirmAddRestPass}
                      className="items_login_page_body_button"
                    >
                      sumit
                    </button>
                  </div>
                </form>
                <div className="items_login_page_body footer">
                  <span className="items_login_page_body_footer_span_last">
                    Don't need to reset your password?
                  </span>
                  <button
                    className="items_login_page_body_footer_btn"
                    onClick={() => handleTabChange("login")}
                  >
                    log in
                  </button>
                </div>
              </div>
            ) : (
              <div className="login_page_body_row">
                {/* <div className="items_login_page_body">
                  <img src={user_img} alt="" />
                </div> */}
                <div className="items_login_page_body header">
                  <h1>إعادة تعيين كلمة المرور</h1>
                </div>
                <form
                  id="login_form_newlogin"
                  onSubmit={handleConfirmAddRestPass}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleConfirmAddRestPass(e);
                    }
                  }}
                >
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body ar">
                      <input
                        type="text"
                        required
                        name="userNameRestPass"
                        value={formData.userNameRestPass}
                        onChange={handleInputChangeRestPass}
                      />
                      <label
                        htmlFor="input"
                        className="label_items_login_page_body_ar"
                      >
                        اسم المستخدم
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>
                  <div className="items_login_page_body">
                    <div className="input_container_items_login_page_body ar">
                      <input
                        type="email"
                        required
                        name="emailRestPass"
                        value={formData.emailRestPass}
                        onChange={handleInputChangeRestPass}
                      />
                      <label
                        htmlFor="input"
                        className="label_items_login_page_body_ar"
                      >
                        البريد الإلكتروني
                      </label>
                      <div className="underline_input_container_items_login_page_body"></div>
                    </div>
                  </div>

                  <div className="items_login_page_body resetpass">
                    <button
                      type="submit"
                      onClick={handleConfirmAddRestPass}
                      className="items_login_page_body_button"
                    >
                      إرسال
                    </button>
                  </div>
                </form>
                <div className="items_login_page_body footer">
                  <span className="items_login_page_body_footer_span_last">
                    لا تحتاج إلى إعادة تعيين كلمة المرور؟
                  </span>
                  <button
                    className="items_login_page_body_footer_btn"
                    onClick={() => handleTabChange("login")}
                  >
                    تسجيل الدخول
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const MessageError = ({
    title,
    para,
    language,
    footer,
    children,
    currentStep,
    showProgress,
  }) => (
    <ErrorPage
      title={title}
      para={para}
      language={language}
      showProgress={showProgress}
      footer={footer}
      currentStep={currentStep}
      saveLoginStatus={saveLoginStatus}
      setIsSubmitted={setIsSubmitted}
    >
      {children}
    </ErrorPage>
  );

  return (
    <>
      <div className="login-form">
        {isSubmitted
          ? (() => {
              if (isSuperUser || isSeniorAnalyzer || isJuniorAnalyzer) {
                return <Navigate to="/base" replace />;
              }
              if (isNormalUser && !isEmailChecked) {
                return (
                  <>
                    {language === "ar" ? (
                      <MessageError
                        title=" تأكيد عنوان بريدك الإلكتروني"
                        para="ابحث عن رسالة التحقق التي أرسلناها إليك على هذا 
                        العنوان وانقر الرابط لتفعيل الحساب "
                        language="ar"
                        showProgress={false}
                      >
                        {email_img}
                      </MessageError>
                    ) : (
                      <MessageError
                        title="Verify Your Email Address"
                        para="Please check your Email and click the link to activate your account."
                        language="en"
                        showProgress={false}
                      >
                        {email_img}
                      </MessageError>
                    )}
                  </>
                );
              }

              if (isNormalUser && isEmailChecked && !isIdentityChecked) {
                return <Navigate to="/personal-info" replace />;
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                isRequestDemoChecked &&
                !isDemoChecked
              ) {
                return <Navigate to="/demomessage" replace />;
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                isDemoChecked
              ) {
                return <Navigate to="/home" replace />;
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                !isTaskChecked
              ) {
                return <Navigate to="/petition" replace />;
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                isTaskChecked &&
                !isPayChecked
              ) {
                return (
                  <>
                    {language === "ar" ? (
                      <MessageError
                        title="تم إرسال الطلب"
                        para="يرجى الانتظار لمراجعة الطلب وتقدير التكلفة."
                        showProgress={true}
                        currentStep={2}
                        language="ar"
                      >
                        {tick_img}
                      </MessageError>
                    ) : (
                      <MessageError
                        title="Your request has been sent"
                        para="our support team will get in touch with you to verify your information"
                        showProgress={true}
                        currentStep={2}
                        language="en"
                      >
                        {tick_img}
                      </MessageError>
                    )}
                  </>
                );
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                isTaskChecked &&
                isPayChecked &&
                !isPreperedChecked
              ) {
                return <Navigate to="/pay" replace />;
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                isTaskChecked &&
                isPayChecked &&
                isPreperedChecked &&
                !isProjectReadyChecked
              ) {
                return (
                  <>
                    {language === "ar" ? (
                      <MessageError
                        title="تمت عملية الدفع بنجاح"
                        para="يرجى الانتظار حتی يقوم خبراؤنا بجمع البيانات التي طلبتها."
                        language="ar"
                        showProgress={true}
                        currentStep={4}
                      >
                        {clock_img}
                      </MessageError>
                    ) : (
                      <MessageError
                        title="Your Payment is complete"
                        para="Your payment has been successfully processed. Please be patient while our experts gather the data you've requested"
                        language="en"
                        showProgress={true}
                        currentStep={4}
                      >
                        {clock_img}
                      </MessageError>
                    )}
                  </>
                );
              }

              if (
                isNormalUser &&
                isEmailChecked &&
                isIdentityChecked &&
                isTaskChecked &&
                isPayChecked &&
                isPreperedChecked &&
                isProjectReadyChecked
              ) {
                return <Navigate to="/home" replace />;
              }

              return renderForm;
            })()
          : renderForm}
      </div>
    </>
  );
}

export default Login;
