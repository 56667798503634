export const base_url =  "https://dev.datakhana.com/";
export const base_img = "https://dl.datakhana.com/social/profiles/"
export const twitter_profile_url = base_img + "twitter/";
export const facebook_profile_url = base_img + "facebook/";
export const instagram_profile_url = base_img + "instagram/";

// Ai
// export const base_url = "http://185.70.187.148:8000/";

export const login_url = "token/"

export const refresh_login_url = "token/refresh/"

export const register_user = "users/publicCreate/"
export const rest_pass_user = "passwordReset/"


export const dashboard_url = "home"

export const session_manager_url = "sessionManager"

export const twitter_dashboard_url = "twitter/home"
export const twitter_post_search_url = "twitter/postsSearch/"
export const twitter_post_action_url = "twitter/posts_actions/"

export const junior_twitter_post_search_url = "twitter/juniorPostsSearch/"
export const senior_twitter_post_action_url = "twitter/senior_posts_actions/"
export const senior_twitter_post_search_url = "twitter/seniorPostsSearch/"


export const instagram_dashboard_url = "instagram/home"
export const instagram_post_search_url = "instagram/postsSearch/"
export const instagram_post_action_url = "instagram/posts_actions/"

export const junior_instagram_post_search_url = "instagram/juniorPostsSearch/"
export const senior_instagram_post_action_url = "instagram/senior_posts_actions/"
export const senior_instagram_post_search_url = "instagram/seniorPostsSearch/"


export const junior_post_search_url = "juniorPostsSearch/"
export const senior_post_search_url = "seniorPostsSearch/"
export const senior_post_action_url = "senior_posts_actions/"

export const post_search_url = "postsSearch/"
export const post_action_url = "posts_actions/"

export const post_delete_url = "posts/delete"
export const post_edit_url = "posts/edit"

export const edit_fake_chart_url = "editFakeChart"
export const fetch_fake_chart_url = "fetchFakeChart"

export const delete_word_filter_url = "deleteWordFilter"
export const edit_word_filter_url = "editWordFilter"
export const fetch_word_filter_url = "fetchWordFilter"

export const twitter_post_delete_url = "twitter/posts/delete"
export const twitter_post_edit_url = "twitter/posts/edit"


export const instagram_post_delete_url = "instagram/posts/delete"
export const instagram_post_edit_url = "instagram/posts/edit"

export const profile_pic_url = 'profilepic/'

export const twitter_profile_pic_url = 'twitter/profilepic/'
export const instagram_profile_pic_url = 'instagram/profilepic/'

export const tokenName = "authTokens"


export const admin_user_list_url = 'users/admin/list/'
export const admin_user_add_url = 'users/admin/create/'
export const admin_user_update_url = 'users/admin/update/'
export const user_delete_url = 'users/delete/'
export const user_list_url = 'users/list/'
export const user_add_url = 'users/create/'
export const user_update_url = 'users/update/'
export const myshow_url = 'users/myshow/'

export const add_personal_information = "socialProfile"
export const get_personal_information = "socialProfile"

export const create_projects_url = 'projects/createProject/'
export const projects_list_url = 'projects/listProject/'
export const delete_project_url = 'projects/deleteProject/'

export const projects_price_list = 'projects/project_bill/list/'
export const projects_price_add = 'projects/bill/'
export const projects_price_edit = 'projects/bill/edit/'
export const price_show_user = 'projects/mybill/'

export const add_subject_url = 'customProjects/addSubject'
export const delete_subject_url = 'customProjects/deleteSubject'
export const delete_sub_subject_url = 'customProjects/deleteSubSubject'
export const add_subSubject_url = 'customProjects/addSubSubject'

export const get_axis_url = 'customProjects/getAxis'
export const add_axis_url = 'customProjects/addAxis'
export const delete_axis_url = 'customProjects/deleteAxis'

export const get_emotion_url = 'customProjects/getEmotion'
export const add_emotion_url = 'customProjects/addEmotion'
export const delete_emotion_url = 'customProjects/deleteEmotion'

export const get_accent_url = 'customProjects/getAccent'
export const add_accent_url = 'customProjects/addAccent'
export const delete_accent_url = 'customProjects/deleteAccent'

export const edit_user_url = 'customProjects/editUsers'
export const add_user_to_project_url = 'projects/adduser/'


export const web_search_dashboard_url = "google/home"
export const web_search_post_search_url = "google/postsSearch/"
export const web_search_post_action_url = "google/posts_actions/"

export const junior_web_search_post_search_url = "google/juniorPostsSearch/"
export const senior_web_search_post_action_url = "google/senior_posts_actions/"
export const senior_web_search_post_search_url = "google/seniorPostsSearch/"

export const web_search_profile_pic_url = 'google/profilepic/'

export const web_search_post_delete_url = "google/posts/delete"
export const web_search_post_edit_url = "google/posts/edit"


// export const facebook_charts = 'facebook/ActionCharts'
// export const twitter_charts = 'twitter/ActionCharts'
// export const instagram_charts = 'instagram/ActionCharts'

export const facebook_socialmedia = "task/"
export const x_socialmedia = "twitter/task/"
export const instagram_socialmedia = "instagram/task/"

export const facebook_socialmedia_edit = "task/edit/"
export const x_socialmedia_edit = "twitter/task/edit/"
export const instagram_socialmedia_edit = "instagram/task/edit/"

export const create_task_facebook = "task/add/"
export const create_task_twitter = "twitter/task/add/"
export const create_task_instagram = "instagram/task/add/"

export const import_file_excel = "profile/batch_task/add/"
export const file_excel_confirm_list = "profile/task/list/"
export const file_excel_confirm_accept = "profile/task/accept/"
export const file_excel_confirm_delete = "profile/task/reject/"

export const import_file_profile_page = "profile/task/add/"

export const user_task_list = "task/users/list"

export const show_scrapped_projects_list = "projects/list/"
export const edit_scrapped_projects_list = "projects/edit/"
export const add_new_project = "projects/add/"

export const export_excel_facebook = "projects/export/facebook/"
export const export_excel_twitter = "projects/export/twitter/"
export const export_excel_instagram = "projects/export/instagram/"


export const statistics_admin_list = "users/statistics/"


export const add_user_filter_maker = "addUserFilter/"
export const list_user_filter_maker = "listUserFilter/"
export const delete_filter_maker = "deleteUserFilter/"
export const edit_filter_maker = "editUserFilter/"


export const get_comments_post = "postComments/"
export const get_comments_post_twitter = "twitter/postComments/"
export const get_comments_post_instagram = "instagram/postComments/"


export const get_news_paper = "newsletterList/"

export const get_users_demo = "users/demo/list"
export const send_req_user_demo = "requestdemo/set/"
export const update_user_demo = "users/demo/update/"
export const changeExpiry_user_demo = "users/changeExpiry/"
